<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>New PPE Request For</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button variant="primary" size="sm" squared @click="doNewPpeRequest()">
                                <b-spinner small v-if="state === 'loading'"></b-spinner> Save</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Request For</label>
                            <b-input-group>
                                <b-form-input v-model="createdFor.name" :disabled="true"></b-form-input>
                                <b-input-group-append>
                                    <b-button size="sm"  text="Button" variant="secondary" @click="openAddUserModal()">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" class="mt-3">
                            <b-alert variant="info" show>Please fill in only the fields of the equiptment you need. Those not filled will be considered as not part of the application for the new PPE.</b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="tablePerishableData.dataSource" 
                                    :fields="tablePerishableData.tableColumns" 
                                    :busy="tablePerishableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                
                                <template #cell(size)="row">
                                    <b-form-input v-model="row.item.size"></b-form-input>
                                    <!-- <b-form-select v-model="row.item.size" :disabled="state === 'loading'">
                                        <b-form-select-option v-for="(item, index) in sizes" :key="index" :value="item">{{item}}</b-form-select-option>
                                    </b-form-select> -->
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                
                                <template #cell(size)="row">
                                    <b-form-input v-model="row.item.size"></b-form-input>
                                    <!-- <b-form-select v-model="row.item.size" :disabled="state === 'loading'">
                                        <b-form-select-option v-for="(item, index) in sizes" :key="index" :value="item">{{item}}</b-form-select-option>
                                    </b-form-select> -->
                                </template>

                                
                                <template #cell(quantity)="row">
                                    <b-form-input v-model="row.item.quantity" type="number" :min="0"></b-form-input>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                    
                    <b-row v-if="state !== 'initiliazing'">
                        <b-button block @click="addMoreModalOpen()" variant="primary">Add more</b-button>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        
        <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
            
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="user.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Surname</label>
                    <b-form-input v-model="user.surname"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Email</label>
                    <b-form-input v-model="user.email"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Clear</b-button>
                    <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col class="12">
                    <b-table striped hover 
                            :items="tableDataUsers.dataSource" 
                            :fields="tableDataUsers.tableColumns"
                            :busy="tableDataUsers.isLoading" 
                            @row-clicked="chooseUser"
                            :per-page="tableDataUsers.resultsPerPage"
                            id="userSearch"
                            :current-page="tableDataUsers.currentPage" sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
          
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableDataUsers.currentPage"
                    :total-rows="userRows"
                    :per-page="tableDataUsers.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal id="add-more-modal" hide-footer no-header @close="addMoreModalClose()">
            <b-row>
                <b-col cols="12">
                    <label>Description</label>
                    <b-form-input v-model="addMore.description"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Quantity</label>
                    <b-form-input v-model="addMore.quantity" type="number" :min="0"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Size</label>
                    <b-form-input v-model="addMore.size"></b-form-input>
                    <!-- <b-form-select v-model="addMore.size" :disabled="state === 'loading'">
                        <b-form-select-option v-for="(item, index) in sizes" :key="index" :value="item">{{item}}</b-form-select-option>
                    </b-form-select> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <label>Image Upload</label>
                     <b-form-file
                        v-model="addMore.imageFile"
                        @change="filesUploaded"
                        placeholder="Choose a file..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" size="sm" squared @click="doAddMore()">
                        <b-spinner small v-if="isDocumentConverting === true"></b-spinner> Save</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    data: () => ({
        state: 'initiliazing',
        createdFor: {name: null},
        addMore: {
            description: null,
            quantity: null,
            size: null,
            perishable: false,
            image: null
        },
        isDocumentConverting: false,
        sizes: ['X-Small', 'Small', 'Medium', 'Large', 'X-Large', 'XX-Large'],
        tablePerishableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Size',
                    key: 'size',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Quantity',
                    key: 'quantity',
                    sortable: false,
                    tdClass: ''
                },
                {
                    label: 'Size',
                    key: 'size',
                    sortable: false,
                    tdClass: ''
                }
            ]
        },
        user: {
            name: null,
            surname: null,
            email: null,
            roleID: 0
        },
        tableDataUsers: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                label: 'Name',
                key: 'name',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Surname',
                key: 'surname',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Email',
                key: 'email',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Contact Number',
                key: 'contactNumber',
                sortable: true,
                tdClass: '',
                },
                {
                label: '',
                key: 'actions',
                sortable: false,
                tdClass: ''
                }
            ]
        },
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'PPE Request'
            },
            {
                text: 'Create For',
                active: true
            }
        ])
        this.getAvailableItems()
        .then((res) => {
            let perishable = []
            let nonreplenishable = []
            res.data.result.forEach(item => {
                if (item.perishable) {
                    perishable.push(item)
                }
                else {
                    nonreplenishable.push(item)
                }
            });
            this.tableData.dataSource = nonreplenishable
            this.tablePerishableData.dataSource = perishable
            this.tableData.isLoading = false
            this.tablePerishableData.isLoading = false
            this.state = 'show'
        })
        .catch(() => {
            this.state = 'show'
            this.tableData.isLoading = false
            this.tablePerishableData.isLoading = false
        })
    
    },
    methods:{
        ...mapActions(['getAvailableItems', 'createNewRequestFor', 'searchUsersRequest', 'uploadAnswerImage']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        ...mapActions('notificationService', ['addErrorMessage', 'clearMessage']),
        chooseUser(rowItem){
            this.state = 'loading'
            this.createdFor = rowItem
            this.state = 'show'
            this.closeSearchUserModal()
        },
        doSearchUsers(){
            this.tableDataUsers.isLoading = true

            let request = []
            
            if (this.user.name != null) {
                request.push({'key': 'name', 'value': this.user.name})
            }
            if (this.user.surname != null) {
                request.push({'key': 'surname', 'value': this.user.surname})
            }
            if (this.user.email != null) {
                request.push({'key': 'email', 'value': this.user.email})
            }
            if (this.user.roleId != null) {
                request.push({'key': 'fkroleId', 'value': this.user.roleId})
            }
            
            this.$store.commit('setUserSearchRequest', request)
            
            this.searchUsersRequest()
            .then((request) => {
                this.tableDataUsers.dataSource = request.data
                this.tableDataUsers.isLoading = false
                this.state  = 'initialize'
            })
            .catch( () => {
                this.tableDataUsers.isLoading = false
            })
        },
        clearUsersFilter(){
            this.user = {
                name: null,
                surname: null,
                email: null
            }
        },
        openAddUserModal(){
            this.$bvModal.show('search-user-modal')
            this.user.roleId = null
            this.doSearchUsers()
        },
        closeSearchUserModal(){
            this.$bvModal.hide('search-user-modal')
        },
        doNewPpeRequest(){
            if (this.createdFor.name === null) {
                this.clearMessage()
                this.addErrorMessage({error: '',message: 'Please select a person to request this for'})
                return
            }

            this.state = 'loading'
            let requestList = this.tablePerishableData.dataSource.concat(this.tableData.dataSource)
            let request = {
                createdFor: this.createdFor.userId,
                ppEitems: requestList
            }

            
            this.$store.commit('setNewPpeRequestRequest', request)
            this.createNewRequestFor()
            .then(() => {
                this.$router.back()
                this.state = 'show'
            })
            .catch(() =>{
                this.state = 'show'
            })

            //console.log(requestList)
        },
        doAddMore(){
            this.tableData.isLoading = true
            this.tableData.dataSource.push(this.addMore)
            this.tableData.isLoading = false
            this.addMoreModalClose()
            this.addMore = {
                description: null,
                quantity: null,
            perishable: false,
                size: null,
                image: null
            }
        },
        addMoreModalOpen(){
            this.$root.$emit('bv::show::modal', 'add-more-modal', '#btnShow')
        },
        addMoreModalClose(){
            this.$root.$emit('bv::hide::modal', 'add-more-modal', '#btnShow')
        },
        
        filesUploaded(e){
          const selectedDocument = e.target.files[0]
          if (selectedDocument.size > 20000000) {
            this.clearMessage()
            this.addErrorMessage({error: '',message: 'Document to big to be uploaded'})
            return
          }
          this.createBase64File(selectedDocument)
          this.uploadFile = null
          },
        createBase64File(file){
          this.isDocumentConverting = true
          const reader = new FileReader()
          
          let that = this 

          reader.onload = (e) => {
            let file1 = e.target.result

            // that.docUpload.base64Data = this.file1
            // that.docUpload.description = that.documentDetails.description
            // that.docUpload.date = new Date ()
            // that.docUpload.documentName = file.name
            // that.docUpload.ticketId = that.selectedJobCard.ticketId

            
                let request = {
                    documentName: file.name,
                    base64Data: file1,
                    description: file.name
                }

                this.$store.commit('setUploadAnswerImageRequest', request)
                this.uploadAnswerImage()
                .then((res) =>{
                    that.addMore.imageDocumentId = res.data.result
                    that.isDocumentConverting = false
                })
                .catch(() => {
                    that.isDocumentConverting = false
                })

          }
          reader.readAsDataURL(file)
      },
    },
    computed:{
        userRows() {
            return this.tableDataUsers.dataSource.length
        }
    }
}
</script>
